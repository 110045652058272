<template>
  <div class="galleryBox">
    <a-modal
        title="Mint"
        class="mintLayer"
        :visible="mintSpiritPopup"
        @ok="_mintSpirit"
        :bodyStyle="{}"
        :footer="null"
        @cancel="hideSpiritMint"
    >
      <template slot="title">
        <div class="mint-title">{{$t('common.mint')}}</div>
      </template>
      <div class="mint-wrap">
        <div class="numberBox">
          <p>0.025 ETH {{$t('home.mintSpirit')}}</p>
          <div class="number">
            <strong>{{$t('common.numbers')}}</strong>
            <input type="hidden" disabled v-model="mintSpiritNumber" />
            <span class="box">
              <div class="sub" @click="subSpiritMint">-</div>
              <span>{{mintSpiritNumber}}</span>
              <div class="add" @click="addSpiritMint">+</div>
            </span>
          </div>
          <div>
            <button class="batch-mint-btn" @click="_mintSpirit">{{$t('home.mintNow')}}</button>
          </div>
        </div>
      </div>
    </a-modal>
    <div class="gallery-logo">
      <img src="../assets/cosmob.png">
    </div>
    <div class="screen-demo">
      <img src="../assets/demo1.jpg">
      <img src="../assets/demo2.jpg">
      <img src="../assets/demo3.jpg">
    </div>
    <div class="spirit">
      <h2>/ {{$t('gallery.spiritItem')}} /</h2>
      <p>{{$t('gallery.title')}}</p>
      <div class="listData">
        <div class="item">
          <img src="../assets/nft-items/cat.png">
          <div class="right">
            <span class="info">
              {{$t('gallery.mirrorcat')}}
            </span>
            <span class="operation">
              <button type="button">{{$t('common.soldout')}}</button>
            </span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/nft-items/pumpkin.png">
          <div class="right">
            <span class="info">
              {{$t('gallery.pumpkin')}}
            </span>
            <span class="operation">
              <button type="button">{{$t('common.soldout')}}</button>
            </span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/nft-items/3.png">
          <div class="right">
            <span class="info">
              {{$t('gallery.floralspirit')}}
            </span>
            <span class="operation">
              <button type="button">{{$t('common.ComingSoon')}}</button>
            </span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/nft-items/pumpkin.png">
          <div class="right">
            <span class="info">
              {{$t('gallery.vase')}}
            </span>
            <span class="operation">
              <button type="button">{{$t('common.ComingSoon')}}</button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="screen-wrap">
      <h2>{{$t('gallery.screen')}}</h2>
      <p class="description">{{$t('gallery.screenTitle')}}</p>
      <div class="screen-item">
        <div class="imgBox">
          <img src="/img/p0.png">
        </div>
        <div class="info">
          <h4 class="screen-title">{{$t('gallery.blankScreen')}}</h4>
          <p class="screen-description">{{$t('gallery.blankScreenContent')}}</p>
        </div>
      </div>
      <div class="screen-item">
        <div class="imgBox">
          <img src="/img/p2.png">
        </div>
        <div class="info">
          <h4 class="screen-title">{{$t('gallery.elderScreen')}}</h4>
          <p class="screen-description">{{$t('gallery.elderScreenContent')}}</p>
        </div>
      </div>
    </div>

    <div class="role-wrap">
      <div class="item">
        <div class="imgBox">
          <img src="../assets/nft-items/r/r1.png">
        </div>
        <div class="info">
          <h3 class="role-title">{{$t('gallery.sageRole')}}</h3>
          <p class="role-description">{{$t('gallery.sageRoleContent')}}</p>
        </div>
      </div>
      <div class="item">
        <div class="imgBox">
          <img src="../assets/nft-items/r/r2.png">
        </div>
        <div class="info">
          <h3 class="role-title">{{$t('gallery.lotusRole')}}</h3>
          <p class="role-description">{{$t('gallery.lotusRoleContent')}}</p>
        </div>
      </div>
    </div>

    <div class="artist">
      <img src="../assets/member/artist.png" alt="">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "GalleryMobile",
  mounted() {
    this.$store.commit('switchNavi', 1)
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'nftContract', 'itemContract']),
  },
  data() {
    return {
      mintSpiritNumber: 10,
      mintSpiritPopup: false,
    }
  },
  methods: {
    subSpiritMint() {
      if (this.mintSpiritNumber <= 0) {
        return;
      }
      this.mintSpiritNumber--;
    },
    addSpiritMint() {
      this.mintSpiritNumber++;
    },
    hideSpiritMint() {
      this.mintSpiritPopup = false
    },
    mintSpirit() {
      this.mintSpiritPopup = true
    },
    async _mintSpirit() {
      if (this.itemContract) {
        const gasPrice = await this.web3.eth.getGasPrice()
        const price = 0.025 * 10**18
        const gasLimit = await this.itemContract.methods.mint(this.mintSpiritNumber).estimateGas({
          from: this.account,
          value: price * this.mintSpiritNumber,
        })

        this.itemContract.methods.mint(this.mintSpiritNumber).send({
          from: this.account,
          value: price * this.mintSpiritNumber,
          gas: gasLimit,
          gasPrice,
        }, function(err, hash) {
          // console.log(err, hash)
        }).on('transactionHash', function(hash) {
          // console.log(hash)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .galleryBox {
    .gallery-logo {
      width: 750px;
      margin-top: 50px;
      margin-bottom: 30px;
      text-align: center;
      img {
        width: 330px;
      }
    }
    .timeBox {
      margin-bottom: 30px;
      font-size: 40px;
      font-weight: bold;
      color: #000;
      text-align: center;
    }
    .art-preview {
      display: flex;
      img {
        width: 250px;
      }
    }
    .spirit {
      width: 600px;
      margin: 50px auto 0;
      padding-top: 80px;
      border-top: 1px solid #979797;
      h3 {
        margin-bottom: 15px;
        font-size: 40px;
        color: #000;
        text-align: center;
      }
      p {
        margin-bottom: 0;
        font-size: 24px;
        color: #000;
        text-align: center;
      }
      .listData {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-top: 150px;
          padding-bottom: 80px;
          img {
            width: 130px;
          }
          .right {
            display: flex;
            flex-direction: column;
            width: 380px;
            span.info {
              font-size: 26px;
              color: #131415;
            }
            span.operation {
              margin-top: 80px;
              display: flex;
              flex-direction: column;
              strong {
                margin-bottom: 10px;
                font-size: 36px;
                color: #000;
              }
              button {
                width: 240px;
                height: 60px;
                font-size: 24px;
                color: #131415;
                border: 0;
                background: #CFCFCF;
                border-radius: 16px;
              }
              button.canUse {
                background-color: #FF750D;
              }
            }
          }
        }
        .item + .item {
          border-top: 1px solid #979797;
        }
      }
    }
    .screen-wrap {
      width: 750px;
      padding: 80px 0;
      background-color: #D8D8D8;
      h3 {
        margin-bottom: 40px;
        font-size: 40px;
        color: #000;
        text-align: center;
      }
      p.description {
        margin-bottom: 80px;
        font-size: 24px;
        color: #000;
        text-align: center;
      }
      .screen-item {
        display: flex;
        justify-content: space-between;
        .imgBox {
          width: 450px;
          img {
            width: 450px;
          }
        }
        .info {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 300px;
          padding: 0 20px;
          color: #000;
          .screen-title {
            font-size: 36px;
            font-weight: bold;
          }
          p.screen-description {
            font-size: 24px;
          }
        }
      }
    }
    .role-wrap {
      margin-top: 120px;
      .item {
        display: flex;
        justify-content: space-between;
        .imgBox {
          width: 475px;
          img {
            width: 475px;
          }
        }
        .info {
          padding: 30px 20px 0 0;
          color: #000;
          h3 {
            margin-bottom: 20px;
            font-size: 36px;
          }
          p {
            font-size: 24px;
          }
        }
      }
      .item + .item {
        margin-top: 50px;
      }
    }

    .artist {
      margin-top: 120px;
      width: 750px;
      padding: 30px 0 90px;
      text-align: center;
      background-color: #D8D8D8;
      img {
        width: 740px;
      }
    }

    h2 {
      font-size: 48px;
      color: #000;
      text-align: center;
      margin-bottom: 20px;
    }

    .screen-demo {
      img {
        width: 33.3%;
      }
    }
  }
</style>
