<template>
  <div class="footer">
    <div class="social-links">
      <a href="https://www.instagram.com/cosmochamber.nft/" target="_blank">
        <img src="/img/ins.png">
      </a>
      <a href="https://discord.gg/TJhzDTkNdN" target="_blank">
        <img src="/img/discord.png">
      </a>
      <a href="https://twitter.com/CosmoChamber" target="_blank">
        <img src="/img/twitter.png">
      </a>
    </div>
    <p class="copyright">©2021 double screens</p>
    <div class="lab">
      <img src="/img/double.png">
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
  }
</script>

<style scoped lang="scss">
  .footer {
    box-sizing: border-box;
    width: 750px;
    padding: 80px 0;
    .copyright {
      margin-bottom: 15px;
      text-align: center;
      font-size: 24px;
      color: #333333;
    }

    .social-links {
      text-align: center;
      color: #333333;
      margin-bottom: 15px;

      img {
        width: 64px;
        margin: 10px;
      }
    }
  }

  .lab {
    text-align: center;
    img {
      width: 180px;
    }
  }
</style>
