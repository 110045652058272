<template>
  <div class="home">
    <div class="bannerBox">
      <div class="bg"><img src="../assets/home/banner.jpg" alt=""></div>
      <h2>{{$t('home.banner.title')}}</h2>
      <p>{{$t('home.banner.content')}}</p>
    </div>
    <div class="space">
      <div class="img-wrap">
        <img src="../assets/cosmob.png">
      </div>
    </div>

    <div class="chamber">
      <div class="item">
        <p>{{ $t('home.p4Content')}}</p>
        <div class="imgBox">
          <img src="/img/phase4.jpg" alt="">
          <div class="mintLayer wl">
            <div class="mint-wrap">
              <div class="numberBox">
                <p>{{totalMint}} {{$t('common.minted')}}</p>
                <div class="number">
                  <strong>{{$t('common.numbers')}}</strong>
                  <input type="hidden" disabled v-model="mintNumber" />
                  <span class="box">
                <div class="sub" @click="subMint">-</div>
                <span>{{mintNumber}}</span>
                <div class="add" @click="addMint">+</div>
                </span>
                </div>
                <a-button class="coming-btn" type="button" @click="publicMint">{{$t('common.wlMint', { price: wlPrice })}}</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about">
      <h3>{{ $t('home.About')}}</h3>
      <p v-html="$t('home.aboutPart1')"></p>
      <img src="/img/artist.png">
    </div>

    <div class="salePlan">
      <h3>/ {{$t('home.ROADMAP')}} /</h3>
      <div class="listBox">
        <div class="item">
          <div class="imgBox"><img src="../assets/home/icon1.png" alt=""></div>
          <h4>{{$t('home.Sales')}}</h4>
          <p>{{$t('home.r1')}}</p>
        </div>
        <div class="item">
          <div class="imgBox"><img src="../assets/home/icon2.png" alt=""></div>
          <h4>{{$t('home.Culture')}}</h4>
          <p>{{$t('home.r2')}}</p>
        </div>
        <div class="item">
          <div class="imgBox"><img src="../assets/home/icon3.png" alt=""></div>
          <h4>{{$t('home.Grant')}}</h4>
          <p>{{$t('home.r3')}}</p>
        </div>
        <div class="item">
          <div class="imgBox"><img src="../assets/home/icon4.png" alt=""></div>
          <h4>{{$t('home.Investor')}}</h4>
          <p>{{$t('home.r4')}}</p>
        </div>
      </div>

      <div class="description" v-html="$t('home.roadmapContent')"></div>
    </div>

    <div class="grant">
      <h3 class="title">Outreach</h3>
      <p class="description">Selected galleries, major institutions and important members of the community are eligible for special rewards. Please contact us for more details.</p>

      <h4>Artists</h4>
      <p class="info">
        GA，An artist who combines painting and literature perfectly
        <br>
        ARMOTOR, an artist integrating Oriental mysticism, Buddhism and tattoo culture
        <br>
        TONG, an artist who was immersed in Chinese Meticulous Painting in the Ming Dynasty
        <br>

        Double, an artist who likes to combine 3D terrain and ancient landscape painting
        <br>
        We will invite new artists to come in CosmoChamber.
      </p>

      <div class="imgBox"><img src="../assets/member/artist.png" alt=""></div>
    </div>

    <div class="partner">
      <h3 class="title">/ {{$t('common.partner')}} /</h3>
      <p class="partner-wrap">
        <div>
          <img src="/img/polygon.png">
        </div>
        <div>
          <a href="https://opensea.io/assets/cosmochamber" target="_blank">
            <img src="../assets/home/partner/opensea.svg">
          </a>
        </div>
        <div>
          <img src="/img/maxon.png" style="width: 100px;">
        </div>
        <div>
          <img src="/img/jiahe.png" style="width: 100px;">
        </div>
        <div>
          <img src="/img/seedao.png" style="width: 100px;">
        </div>
        <div>
          <a href="https://www.element.market/collections/cosmochamber" target="_blank">
            <img src="../assets/home/partner/element.svg">
          </a>
        </div>
        <div>
          <img src="/img/M50.png" style="width: 80px;">
        </div>
        <div>
          <img src="/img/NFTcalendar.png">
        </div>
        <div>
          <img src="/img/HUACHENAUCTIONS.png">
        </div>
        <div>
          <img src="/img/postmasters.png" style="width: 160px;">
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import api from "../utils/api";
import BigNumber from 'bignumber.js'
import { Icon, message } from 'ant-design-vue'
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2762068_l3zcuncvgdk.js',
})

export default {
  name: 'homeMobile',
  components: {
    IconFont
  },
  data() {
    return {
      gasPrice: 0,
      balance: 0,
      totalMint: 0,
      mintedNumber: 0,
      timer: null,
      isEnable: null,
      userMint: 0,
      price: 0.1,
      wlPrice: 0.1,
      wlLimit: 3,
      loading: null,
      isLoading: false,
      mintNumber: 1,
      screens: [{
        title: 'Phase Three',
        description: 'The hedonism of living in the moment',
        rate: 4,
        image: '/img/s3.jpg',
        subImage: '/img/pumpkin.png',
      }],
      sprits: [{
        rate: 4,
        rarible: 'Ultra Rare',
        price: '0.08 ETH',
        active: 0,
        subImage: '/img/pumpkin.png',
      }]
    }
  },
  async mounted() {
    const _this = this
    this.$store.commit('switchNavi', 0);
    this.timer = setTimeout(async function() {
      if (_this.nftContract) {
        _this.totalMint = await _this.nftContract.methods.currentNFTid().call()
      }

      _this.gasPrice = await _this.web3.eth.getGasPrice()
      if (_this.account) {
        _this.balance = await _this.web3.eth.getBalance(_this.account) / 10**18
      }
      clearInterval(_this.timer)
    }, 500)
  },
  computed: {
    ...mapState(['account', 'mintData', 'web3', 'networkid', 'nav', 'nftContract', 'saleContract']),
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    subMint() {
      if (this.mintNumber <= 1) {
        return;
      }
      this.mintNumber--;
    },
    addMint() {
      if (this.mintNumber >=10 ) {
        return
      }
      this.mintNumber++;
    },
    async publicMint() {
      const _this = this
      // 獲取可以 mint 數量
      debugger
      const totalPrice = new BigNumber(this.price).times(this.mintNumber)
      let gasPrice = this.gasPrice
      if (!gasPrice) {
        gasPrice = await this.web3.eth.getGasPrice()
        _this.gasPrice = gasPrice
      }

      let balance = this.balance
      debugger
      if (!balance) {
        balance = await this.web3.eth.getBalance(this.account) / 10**18
        this.balance = balance
      }
      message.destroy()

      if (balance < totalPrice.toNumber()) {
        return message.error(this.$t('tips.fundNotEnough'))
      }

      let loadingItem = null
      loadingItem = this.$loading.show({
        container: null,
        canCancel: false,
        color: '#ee7e33',
        width: 140,
        height: 140,
        onCancel: function() {},
      })
      this.isLoading = true

      const gasUsed = [0,122004,132072,159240,186408,213576,240744,267912,295080,322248,349416,376584,403752,430920,458088,485256,512424,539592,566760,593928,621096]
      let gasLimit = gasUsed[this.mintNumber]

      this.saleContract.methods.publicMint(this.mintNumber).send({
        from: this.account,
        gasPrice,
        value: totalPrice.times(10**18),
        gas: gasLimit,
      }, function(err, hash) {
        if (err) {
          loadingItem.hide()
          _this.isLoading = false
          _this.handleError(err)
        }
      }).on('receipt', function(receipt) {
        loadingItem.hide()
        _this.isLoading = false
        message.success('Mint success')
      })
    },
    handleError(err) {
      if (err.code === 4001) {
        message.error(this.$t('tips.cancelTx'))
      } else if (err.code === -32603) {
        message.error(err.message.replace('Error: execution reverted: ', ''))
      } else {
        let errorMessage = ''
        if (err.message) {
          errorMessage = err.message.replace('Error: execution reverted: ')
        } else if (typeof err === 'Object') {
          errorMessage = JSON.stringify(err)
        } else {
          errorMessage = err
        }
        message.error(errorMessage)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    .mintLayer.wl {
      width: 358px;
      position: absolute;
      left: 1px;
      bottom: 1px;

      .numberBox {
        box-sizing: border-box;
        margin: 0 auto 0;
        padding: 24px;
        overflow: hidden;
        border-radius: 16px 16px 0 0;
        background-color: #fff;
      }

      .numberBox p {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .coming-btn {
      background: #FF750D;
      border-radius: 6px;
      border: none;
      margin-top: 40px;
      width: 100%;
      height: 40px;
      &:hover, &:focus {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .mint-img {
      visibility: hidden;
    }
    .bannerBox {
      box-sizing: border-box;
      position: relative;
      width: 750px;
      height: 775px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 66px;

      h2 {
        width: 90%;
      }

      .bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        height: 775px;
        img {
          height: 775px;
        }
      }

      p {
        margin-bottom: 20px;
        font-size: 24px;
        color: #000;
      }
    }

    .cosmob {
      width: 750px;
      padding: 55px 0 0;

      .combine {
        width: 100%;
        text-align: center;
        img {
          width: 80%;
        }
      }
      .top {
        margin-bottom: 55px;
        text-align: center;
        img {
          width: 330px;
        }
      }

      .imgBox {
        width: 100%;
        text-align: center;
        img {
          width: 80%;
        }
      }

      .content {
        box-sizing: border-box;
        width: 750px;
        padding: 0 6px;
        overflow: hidden;
        text-align: center;
        .logo {
          width: 40%;
          margin-top: 50px;
        }
        .now {
          margin: 0 auto;
          text-align: center;
          width: 576px;
          height: 576px;
          border-radius: 50%;
          background-color: #FEDB7F;
          color: #000;
          .data {
            font-size: 18px;
            text-align: center;
          }
          h3 {
            width: 300px;
            text-align: center;
            font-size: 24px;
            height: 30px;
            font-weight: bold;
            margin: 0 auto;
          }
          p {
            width: 350px;
            font-size: 20px;
            text-align: center;
          }
          .imgBox {
            img {
              width: 120px;
              margin-bottom: 14px;
            }
          }
          .btnBox {
            margin: 80px auto 20px;
            text-align: center;
            width: 200px;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
            font-weight: bold;
            color: #131415;
            background: #FF750D;
            border-radius: 10px;
          }
        }
        .next {
          position: absolute;
          display: none;
          z-index: -1;
          right: 6px;
          width: 576px;
          height: 576px;
          //display: flex;
          align-items: center;
          justify-content: flex-end;
          border-radius: 50%;
          background-color: #FFF6E0;
          p {
            width: 120px;
          }

          img {
            width: 160px;
          }
        }
      }
    }

    .countDownBox {
      margin-top: 30px;
      width: 750px;
      p {
        margin-bottom: 20px;
        font-size: 40px;
        text-align: center;
        color: #FCC737;
      }
      .countDown {
        width: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        & > span {
          margin-right: 10px;
        }
        strong {
          color: #999;
        }
      }
    }

    .space {
      width: 750px;
      margin-top: 140px;

      .img-wrap {
        margin: 0 auto;
        text-align: center;
        img {
          width: 50%;
        }
      }

      h3 {
        margin-bottom: 20px;
        font-size: 30px;
        color: #606060;
        text-align: center;
      }
      p {
        font-size: 24px;
        color: #000;
        text-align: center;
        margin-bottom: 60px;
      }
    }

    .chamber {
      width: 750px;
      h3 {
        font-size: 36px;
        color: #000;
        text-align: center;
      }

      .state {
        margin-top: 10px;
        font-size: 24px;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 360px;
        color: #000;
        margin: 60px auto 30px;
        .title {
          font-size: 30px;
          font-weight: bold;
        }
        p {
          font-size: 24px;
          text-align: center;
        }
        .imgBox {
          width: 360px;
          img {
            width: 360px;
          }
        }
        .star {
          margin-top: 20px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
        }
        .rareText {
          font-size: 32px;
        }
        .price {
          font-size: 36px;
        }
      }
      .item + .item {
        margin-top: 40px;
      }
    }

    .spirit {
      width: 750px;
      margin-top: 80px;
      h3 {
        font-size: 36px;
        color: #000;
        text-align: center;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 360px;
        color: #000;
        margin: 0 auto;
        .imgBox {
          margin-bottom: 34px;
          img {
            width: 128px;
          }
        }
        .btnBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 24px;
          color: #131415;
          button {
            padding: 0 40px;
            height: 48px;
            font-size: 24px;
            border: 0;
            font-weight: bold;
            color: #131415;
            background: #D8D8D8;
            border-radius: 10px;
            &.mint {
              background-color: #FF750D;
            }
          }
        }
        .info {
          font-size: 24px;
        }
      }
      .item + .item {
        margin-top: 30px;
      }
    }

    //.box {
    //  width: 100%;
    //  margin-top: 80px;
    //  color: #000;
    //  h3 {
    //    width: 640px;
    //    font-size: 36px;
    //    text-align: center;
    //    margin: 0 auto 28px;
    //  }
    //  .imgBox {
    //    position: relative;
    //    width: 640px;
    //    height: 250px;
    //    margin-bottom: 35px;
    //    background-color: #606060;
    //    img {
    //      width: 640px;
    //      height: 250px;
    //    }
    //    span {
    //      position: absolute;
    //      left: 0;
    //      top: 0;
    //      right: 0;
    //      bottom: 0;
    //      display: flex;
    //      align-items: center;
    //      justify-content: center;
    //      font-size: 100px;
    //      color: #fff;
    //      font-weight: bold;
    //    }
    //  }
    //  .star {
    //    img {
    //      width: 24px;
    //      height: 24px;
    //      margin-right: 5px;
    //    }
    //  }
    //  p {
    //    margin-bottom: 15px;
    //    font-size: 32px;
    //    color: #FF7213;
    //  }
    //  .price {
    //    margin-bottom: 30px;
    //    font-size: 36px;
    //  }
    //  .btnBox {
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    width: 240px;
    //    height: 60px;
    //    font-size: 20px;
    //    color: #131415;
    //    background: #FFC938;
    //    border-radius: 7px;
    //  }
    //}

    .about {
      box-sizing: border-box;
      width: 750px;
      padding: 90px 80px 100px;
      margin-top: 80px;
      background-color: #000;

      img {
        width: 100%;
      }

      h3 {
        margin-bottom: 40px;
        font-size: 40px;
        text-align: center;
        color: #fff;
      }
      .imgBox {
        width: 580px;
        margin: 0 auto 190px;
        img {
          width: 580px;
        }
      }
      h4 {
        margin-bottom: 40px;
        font-size: 28px;
        color:#FFC938;
      }
      p {
        margin-bottom: 30px;
        font-size: 24px;
        color: #fff;
      }
      .subtitle {
        margin-top: 120px;
      }
      .btnBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 60px;
        margin: 130px auto 0;
        font-size: 20px;
        color: #131415;
        background: #FFC938;
        border-radius: 7px;
      }
    }

    .salePlan {
      width: 750px;
      margin-top: 130px;
      h3 {

        font-size: 40px;
        text-align: center;
      }
      .listBox {
        box-sizing: border-box;
        width: 750px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 80px;
        margin-bottom: 100px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 280px;
          margin-top: 70px;
          .imgBox {
            width: 76px;
            height: 76px;
            margin-bottom: 50px;
            img {
              width: 76px;
              height: 76px;
            }
          }
          h4 {
            margin-bottom: 20px;
            font-size: 24px;
            color: #333;
          }
          p {
            font-size: 18px;
            color: #666;
          }
        }
      }
      .description {
        padding: 0 40px;
        font-size: 28px;
        line-height: 40px;
        color: #666;
      }
      .btnBox{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 60px;
        margin: 90px auto 0;
        font-size: 20px;
        color: #131415;
        background: #FFC938;
        border-radius: 7px;
      }
    }

    .grant {
      display: none;
      box-sizing: border-box;
      width: 750px;
      padding: 120px 0;
      margin-top: 120px;
      background-color: #104B2F;
      h3 {
        margin-bottom: 80px;
        font-size: 40px;
        color: #fff;
        text-align: center;
      }
      p.description {
        margin: 0 40px 150px;
        font-size: 30px;
        color: #fff;
      }
      h4 {
        margin: 0 40px 10px;
        font-size: 28px;
        color: #FCC737;
      }
      p.info {
        margin: 0 40px;
        font-size: 24px;
        color: #fff;
      }
      .imgBox {
        width: 725px;
        margin-top: 150px;
        img {
          width: 725px;
        }
      }
    }
    .commingSoon {
      width: 750px;
      margin-top: 120px;
      margin-bottom: 75px;
      font-size: 40px;
      color: #111;
      text-align: center;
    }
    .roadmap {
      box-sizing: border-box;
      width: 750px;
      padding: 0 40px;
      h3 {
        margin-bottom: 50px;
        font-size: 28px;
        color: #333;
      }
      p {
        margin-bottom: 70px;
        font-size: 24px;
        color: #666;
      }
      .imgBox {
        width: 100%;
        text-align: center;
        img {
          width: 450px;
        }
      }
    }
    .team {
      box-sizing: border-box;
      width: 750px;
      margin-top: 120px;
      padding: 80px 0;
      background-color: #131415;
      h3 {
        margin-bottom: 70px;
        font-size: 40px;
        color: #fff;
        text-align: center;
      }

      .member-wrap {
        width: 400px;
        height: 500px;
        margin: 40px auto 0;
        color: white;
        text-align: center;
        font-size: 18px;
        p {
          padding-top: 10px;
          margin-bottom: 0;
        }
        img {
          width: 400px;
          height: 400px;
        }
      }
    }

    .faq {
      width: 750px;
      margin: 80px 0 150px;
      h3 {
        margin-bottom: 50px;
        font-size: 40px;
        text-align: center;
        color: #000;
      }
      h4 {
        margin: 0 40px 24px;
        font-size: 28px;
        color: #FCC737;
      }
      p {
        margin: 0 40px;
        font-size: 24px;
        color: #000;
      }
    }
  }

  .partner {
    h3 {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 40px;
      text-align: center;
      color: #000;
    }

    div {
      text-align: center;
      margin: 60px 0;
      img {
        width: 34%;
      }
    }
  }


  .box p.cosmo-subtitle {
    font-size: 3.2vw;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
  }

  .transparent {
    opacity: .5;
  }

  h2 {
    font-size: 44px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
  }
</style>
