<template>
  <div class="vipBox">
    <div class="title">VIP claim</div>
    <div class="btnBox">
      <button type="button" class="claim" v-if="isClaim" @click="claim">claim</button>
      <button type="button" disabled v-else>coming soon</button>
    </div>
    <div class="infoBox">
      <p>Each of our investors and major partners can claim a unique, special piece for free. They are hidden symbols that signal your distinctive status and/or important contribution to the project, and cannot be obtained from regular channels. The right to claim one is reserved for VIPs which are to be differentiated from the collectors on the whitelist.</p>
    </div>
    <div class="imgBox">
      <img src="../assets/grant/frog.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "vip",
  data() {
    return {
      isClaim: false
    }
  },
  methods: {
    claim() {
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .vipBox {
    width: 750px;
    padding: 50px 0;
    .title {
      width: 750px;
      text-align: center;
      font-size: 44px;
      color: #000;
      margin-bottom: 20px;
    }
    .btnBox {
      width: 750px;
      text-align: center;
      button {
        width: 600px;
        height: 80px;
        font-size: 28px;
        color: #111;
        border: 0;
        background: #E0E0E0;
        border-radius: 16px;
      }
      button.claim {
        background-color: #FFC938;
      }
    }
    .infoBox {
      width: 700px;
      margin: 50px auto 0;
      p {
        font-size: 28px;
        color: #131415;
        text-align: justify;
      }
    }
    .imgBox {
      width: 750px;
      text-align: center;
      img {
        width: 750px;
      }
    }
  }
</style>