import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueI18n from 'vue-i18n'
// import "vue-navigation-bar/dist/vue-navigation-bar.css"
// import VueNavigationBar from "vue-navigation-bar"
// Vue.component("vue-navigation-bar", VueNavigationBar)
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.config.productionTip = false

Vue.use(antd)
  .use(VueI18n)
  .use(VueLoading)

const messages = require('./constants/i18n')

const i18n = new VueI18n({
  locale: 'zh',
  messages,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
