import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeMobile from '../views/HomeMobile.vue'
import GalleryMobile from '../views/GalleryMobile.vue'
import GrantMobile from '../views/GrantMobile.vue'
import Vip from '../views/Vip.vue'
import Punk from '../views/Punk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeMobile',
    component: HomeMobile
  },
  {
    path: '/gallery',
    name: 'GalleryMobile',
    component: GalleryMobile,
  },
  {
    path: '/grant',
    name: 'GrantMobile',
    component: GrantMobile,
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip,
  }, {
    path: '/punk',
    name: 'Punk',
    component: Punk,
  }, {
    path: '/cryptopunk',
    name: 'Punk',
    component: Punk,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
