<template>
  <div class="header">
    <span class="logo">COSMO CHAMBER NFT</span>
    <Wallet ref="wallet"></Wallet>
    <div class="navImg" @click="changeMenu(true)">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="nav" v-show="menuShow">
      <a href="/">{{$t('nav.home')}}</a>
      <a href="/gallery">{{$t('nav.gallery')}}</a>
      <a :href="'https://opensea.io/'+account" target="_blank">
        OPENSEA
      </a>
      <div class="lang-options">
        <span class="lang-item" @click="switchLang('zh')" :class="{active: 'zh' === lang}">中文 CN</span>
        <span class="lang-item" @click="switchLang('en')" :class="{active: 'en' === lang}">ENGLISH</span>
      </div>
    </div>
    <div class="bg" v-show="menuShow" @click="changeMenu(false)"></div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex"
import Wallet from '../components/Wallet'

export default {
  name: "headerMobile",
  watch: {
    nav: function(value) {
      this.current = value
    },
    locale (val) {
      this.$i18n.locale = val
    }
  },
  components: {
    Wallet,
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav']),
    shortAccount() {
      if (this.account) {
        return this.account.substr(0, 6) + '....' + this.account.substr(this.account.length - 6, this.account.length - 1)
      }

      return ''
    },
  },
  methods: {
    ...mapMutations(['setLang']),
    switchNav(nav) {
      this.$store.commit('switchNavi', nav)
    },
    switchLang(lang) {
      this.lang = lang
      this.setLang(lang)
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    },
    async connect() {
      if (!this.web3) {
        alert('Please install metamask first')
        return
      } else {
        if (this.account) {
          // 显示下拉或到个人中心
          return
        } else {
          const networkid = await this.web3.eth.net.getId()
          if (Number(networkid) === 1) {
            this.$refs.wallet.checkAccounts()
          } else {
            alert('Please use Mainnet')
            return
          }
        }
      }
    },
    changeMenu(bool) {
      this.menuShow = bool
    }
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'en',
      menuShow: false,
      current: this.nav,
    }
  },
  mounted() {
    this.$store.commit('switchNavi', 1)
    this.$i18n.locale = this.lang
  }
}
</script>

<style lang="scss">
  .header {
    position: relative;
    width: 750px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #121212;

    .lang-options {
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    .lang-item {
      cursor: pointer;
      width: 36px;
      font-size: 28px;
      margin: 0 40px;
      color: #CCC;
      &.active {
        color: #000;
      }
    }

    .logo {
      margin-left: 42px;
      width: 303px;
      height: 30px;
      font-size: 24px;
      color: #fff;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
    .navImg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 36px;
      span {
        width: 43px;
        height: 3px;
        background-color: #FFC938;
      }
      span + span {
        margin-top: 10px;
      }
    }
    .nav {
      position: absolute;
      z-index: 2;
      width: 750px;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      a {
        padding: 10px 20px;
        color: #121212;
        font-size: 28px;
        border-bottom: 1px solid rgba(0,0,0,0.2);
      }
    }
    .bg {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #121212;
    }
  }
</style>
